import { template as template_ec0c94359a764cde823552a5b0aab48e } from "@ember/template-compiler";
const FKLabel = template_ec0c94359a764cde823552a5b0aab48e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
