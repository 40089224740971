import { template as template_fadf8c3b4eb44fcb99355da75e21b2f5 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_fadf8c3b4eb44fcb99355da75e21b2f5(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
